@import './variables/index.less';
@import './base/index.less';
@import './mixins/index.less';
@import './main.less';


.iconfont {
  vertical-align: middle;
}

@base-color: #f44336;