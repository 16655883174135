
a {
  color: #e5e7eb;
  text-decoration: none;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

body {
  color: #4a4a4a;
  background: #373e57;
  font-size: 14px;
  line-height: 1.5;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.img-responsive {
  display: inline-block;
  height: auto;
  max-width: 100%;
}

.container {
  &-fluid {
    width: 100%;
    .margin-auto();
  }
}
