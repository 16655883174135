.tag {
  display: flex;
  color: #efefef;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 5px;
  font-size: 12px;
  .color {
    margin-right: 5px;
    border-radius: 2px;
  }
}

@base-color: #f44336;