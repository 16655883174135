.aritcle-item {
  background-color: #121423;
  width: 300px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 4px;
  margin-right: 20px;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 300ms ease-in-out;
  border:1px solid #121423;
  perspective: 100;

  &:hover {
    transform: scale(1.05) rotateZ(-1deg);
    z-index: 2;
    border: 1px solid #000;
    background-color: #000;
  }

  .publish-time {
    color: #595c6c;
    font-size: 12px;
  }

  .time-label {
    margin-right: 10px;
  }
  .title {
    font-size: 24px;
    color: #fff;
    margin-top: 20px;
    white-space: wrap;
    margin-bottom: 30px;
  }
  .tags-container {
    display: flex;
    flex-wrap: wrap;
  }

  .author {
    margin-top: 40px;
    color: #5c5e6e;
    font-size: 14px;
  }
}

@base-color: #f44336;